import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image/withIEPolyfill"

const Work = () => {
  const posts = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              projectTitle
              name
              projectLink
              tools
              role
              featureImage {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1000) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <section className="work">
      <h2 className="work__heading">Old Projects</h2>
      <div className="work-list">
        {posts.allMarkdownRemark.nodes.map(post => {
          return (
            post.frontmatter.featureImage !== null && (
              <a
                href={post.frontmatter.projectLink}
                className="work-list__item"
                key={post.fields.slug}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <div className="item-image">
                  <Img
                    className=""
                    alt={projectTitle}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    fluid={post.frontmatter.featureImage.childImageSharp.fluid}
                  />
                </div> */}
                <div className="item-content">
                  <div className="item-content__title">
                    {post.frontmatter.projectTitle}
                  </div>
                  <div>Role: {post.frontmatter.role}</div>
                  {/* <div>
                    <strong>Client:</strong> {post.frontmatter.name}
                  </div> */}
                  <div>Tools: {post.frontmatter.tools}</div>
                </div>
              </a>
            )
          )
        })}
      </div>
    </section>
  )
}

export default Work
