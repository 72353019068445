import React, { forwardRef } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Contact = forwardRef((props, ref) => {
  const AniBgColor =
    typeof window !== `undefined`
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "#ecfd08"
        : "#1707fd"
      : null

  return (
    <>
      <section id="get-in-touch" className="contact">
        <h2 ref={ref} className="contact__heading">
          Get in touch
        </h2>
        <p>
          You have an exciting project, a topic you'd like to talk about or want
          to grab a coffee? Even if it's only via screen these days, send me an
          email, message me on Telegram or use one of the other channels!
        </p>
        <ul className="contact__list">
          <li>
            <a href="mailto:mail@dirkschmid.de?subject=Hej">Mail</a>
          </li>
          <li>
            <a href="https://t.me/dirkschmid">Telegram</a>
          </li>
          <li>
            <a href="https://twitter.com/diggler3000">Twitter</a>
          </li>
          <li>
            <a href="https://instagram.com/dirk.schmid">Instagram</a>
          </li>
          <li>
            <a href="https://linkedin.com/in/dirkschmid">LinkedIn</a>
          </li>
        </ul>
      </section>
      <p className="legal-link">
        <AniLink cover bg={AniBgColor} to="/legal-notice" duration={0.7}>
          Legal notice
        </AniLink>
      </p>
    </>
  )
})

export default Contact
