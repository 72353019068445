import React, { useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Welcome from "../components/welcome"
import Work from "../components/work"
import Clients from "../components/clients"
import Contact from "../components/contact"
import About from "../components/about"
import Skills from "../components/skills"

const IndexPage = () => {
  const contactRef = useRef()

  return (
    <Layout>
      <SEO title="Dirk Schmid - Frontend Developer with UX Design background" />
      <Welcome scrollToContactRef={contactRef} />
      <Work />
      <About />
      <Clients />
      <Skills />
      <Contact ref={contactRef} />
    </Layout>
  )
}

export default IndexPage
