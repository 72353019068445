import React from "react"

let today = new Date(2021, 9, 1).getTime()
let startFreeelancing = new Date(2010, 9, 1).getTime() //Jun 22 2019 in millisecond

function yearsDiff(startFreeelancing) {
  let date1 = new Date(today)
  let date2 = new Date(startFreeelancing)
  let yearsDiff = date1.getFullYear() - date2.getFullYear()
  return yearsDiff
}

const Welcome = () => (
  <section className="about">
    <h2 className="about__headline">About</h2>
    <div className="about__copy">
      <p>
        I have been a freelancer for more than {yearsDiff(startFreeelancing)}{" "}
        years, and I loved it, especially working with people from different
        professions in various industries and branches. They hired me because I
        keep an eye on the User Experience while developing and testing their
        products. Be it a responsive website, web apps, shops or living style
        guides.
      </p>
    </div>
  </section>
)

export default Welcome
