import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Clients = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: { fields: frontmatter___title, order: DESC }) {
          edges {
            node {
              id
              frontmatter {
                name
                logo
                link
                clientLogo {
                  childImageSharp {
                    fixed(grayscale: true, width: 170, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const clientList = data.allMarkdownRemark.edges

  return (
    <section className="clients">
      <h2 className="clients__heading">Clients</h2>
      <ul className="client-list">
        {clientList.map((edge, index) => {
          return (
            <li key={edge.node.id + index} className="client-list__item">
              <Img
                className="client-image"
                alt={edge.node.frontmatter.name + " logo"}
                objectFit="contain"
                objectPosition="50% 50%"
                fixed={edge.node.frontmatter.clientLogo.childImageSharp.fixed}
              />
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default Clients
