import React from "react"

const Welcome = props => (
  <section className="welcome">
    <h2>Dirk Schmid</h2>
    <div className="welcome__copy">
      Project Manager & Frontend Developer with UX Design
      background.
    </div>
    <button
      className="welcome__contact-anchor"
      onClick={() =>
        props.scrollToContactRef.current.scrollIntoView({ behavior: "smooth" })
      }
    >
      Get in touch <span>&#9660;</span>
    </button>
  </section>
)

export default Welcome
