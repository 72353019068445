import React from "react"

const Skills = () => (
  <section className="stack">
    <h2 className="stack__headline">Technology Stack</h2>
    <ul className="stack__list">
      <li>HTML5</li>
      <li>Sass</li>
      <li>Javascript</li>
      <li>React</li>
      <li>Webpack</li>
      <li>PHP</li>
      <li>Jest</li>
      <li>Cypress</li>
      <li>Craft CMS</li>
      <li>Kirby CMS</li>
    </ul>
  </section>
)

export default Skills
